import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = ({pageContext}) => {

    return <Layout
        pageContext={pageContext}
    >
        <Seo title="404: Not found" />
        <div className="full-page-content">
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
    </Layout>
};

export default NotFoundPage
